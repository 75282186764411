import React from 'react';
import classes from './PricingTier.module.css';
import {useNavigate} from "react-router-dom";

const PricingTier = ({price, currency, typeIdentifier, subheading, selectCallback, selected}) => {
    const thisIsSelected = selected ? (selected === typeIdentifier) : false;
    const navigate = useNavigate();

    return (
        <div className={thisIsSelected ? classes.pricingTierSelected : classes.pricingTier}
             onClick={selectCallback ? () => selectCallback(typeIdentifier) : () => {
             navigate('/subscribe')}}>
            <p className={classes.price}>{currency}<span>{price}</span></p>
            <p className={classes.subtext}>{subheading}</p>
            <button
                className={classes.button}>{selectCallback ? thisIsSelected ? "Selected" : "Select" : "Start Trial"}</button>
        </div>
    );
};

export default PricingTier;