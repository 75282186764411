import React, {useEffect, useState} from 'react';
import classes from "./Checkout.module.css";
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";

const Checkout = ({subscription}) => {
    const [error, setError] = useState(null);
    const [setupIntent, setSetupIntent] = useState(null);

    const stripe = useStripe();
    const stripeElements = useElements();

    useEffect(() => {
        if (stripe == null) {
            setSetupIntent(null);
        } else {
            stripe.retrieveSetupIntent(subscription.stripeClientSecretId).then((response) => {
                setSetupIntent(response.setupIntent);
            });
        }
    }, [stripe, setSetupIntent, subscription]);

    const handlePaymentSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        await stripeElements.submit();

        let {error} = await stripe.confirmSetup(
            {
                clientSecret: subscription.stripeClientSecretId,
                elements: stripeElements,
                redirect: 'always',
                applePay: {
                    recurringPaymentRequest: {
                        paymentDescription: 'Pragmate subscription',
                        managementURL: 'https://pragmate.org/app',
                        regularBilling: {
                            amount: subscription.type.price,
                            label: 'Subscription fee - ' + subscription.type.displayName + ' plan',
                            recurringPaymentIntervalUnit: subscription.type.duration === 'MONTHS' ? 'month' : 'year',
                            recurringPaymentIntervalCount: 1,
                        },
                    },
                },
                confirmParams: {
                    return_url: window.location.href + '?completed',
                    payment_method_data: {
                        billing_details: {
                            email: subscription.user
                        }
                    }
                }
            });

        if (error) {
            setError(error.message);
        }
    }

    return (
        <div className={classes.checkoutContainer}>
            <form onSubmit={handlePaymentSubmit}>
                <h1 className={classes.title}>How do you want to pay for your subscription?</h1>
                <div className={classes.breakdown}>
                    <div className={classes.line}>
                        <p className={classes.price}>{subscription?.type.currency === 'GBP' ? '£' : subscription?.type.currency === 'USD' ? '$' : ''}0</p>
                        <p className={classes.per}>Today</p>
                    </div>
                    <div className={classes.line}>
                        <p className={classes.price}>{subscription?.type.currency === 'GBP' ? '£' : subscription?.type.currency === 'USD' ? '$' : ''}{subscription.type.price / 100}</p>
                        <p className={classes.per}>{subscription.type.displayName}</p>
                    </div>
                </div>
                <PaymentElement
                    options={{fields: {billingDetails: {email: 'never'}}}}/>
                {error ? <h3 className={classes.errorMessage}>{error}</h3> : ''}
                <button className={classes.checkoutButton} type="submit">Pay Now</button>
            </form>
        </div>
    );
};

export default Checkout;