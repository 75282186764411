import React, {useEffect} from 'react';
import classes from "./pages/SubscribePage.module.css";
import {useStripe} from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import {handleError, wrapApiCallWithCsrf} from "../api/api";

const CheckoutCompletion = ({creatingSubscription, setCreatingSubscription, flowCompleted, setFlowCompleted}) => {
    const stripe = useStripe();
    const navigate = useNavigate();
    const [processing, setProcessing] = React.useState(false);
    const [error, setError] = React.useState(false);

    const goToAccount = () => {
        navigate('/app');
    }

    useEffect(() => {
        // wait for stripe to load since we pass a promise to Elements
        if (!stripe)
            return;

        // here were have been redirected from payment - check status, let the server know
        if (creatingSubscription) {
            // retrieve setupintent from stripe (we could use subscription but we'll use this for now)
            const clientSecret = new URLSearchParams(window.location.search).get(
                'setup_intent_client_secret'
            );

            stripe.retrieveSetupIntent(clientSecret).then(({setupIntent}) => {
                switch (setupIntent.status) {
                    case 'succeeded':
                        // finished - tell the server to create the subscription and we can continue
                        wrapApiCallWithCsrf({
                            url: '/subscriptions/create/checkout',
                            method: 'POST',
                        }, () => {
                            // on success tell the user to go to account
                            setFlowCompleted(true);
                            setCreatingSubscription(false);
                        }, (error) => {
                            // detect conflict and redirect to account
                            if (error.response && error.response.status === 409) {
                                navigate('/app');
                            } else {
                                handleError(error, navigate);
                            }
                        });
                        break;
                    case 'processing':
                        setProcessing(true);
                        break;
                    case 'requires_payment_method':
                        setError(true);
                        break;
                    default:
                }
            });
        }
    }, [creatingSubscription, navigate, setCreatingSubscription, setFlowCompleted, stripe]);


    if (creatingSubscription) {
        // this phase is valid for native
        return (
            <div className={classes.completionContainer}>
                {processing ?
                    <h1 className={classes.title}>Your payment is still being processed, please cheack back later.</h1>
                    : error ?
                        <h1 className={classes.title}>An error occurred, please try again.</h1>
                        : <h1 className={classes.title}>Please wait, your request is being processed.</h1>
                }
            </div>
        )
    } else if (flowCompleted) {
        // this phase is valid for native
        return (
            <div className={classes.completionContainer}>
                <h1 className={classes.title}>Your details have been processed.</h1>
                <button onClick={goToAccount}>Go to your account</button>
            </div>
        );
    }
};

export default CheckoutCompletion;