import React, {useEffect, useRef, useState} from 'react';
import classes from './QueryBox.module.css';
import {QueryDto} from "../api/schema";
import {handleError, wrapApiCallWithCsrf} from "../api/api";
import {useNavigate} from "react-router-dom";

const QueryBox = ({id}) => {
    const inputArea = useRef(null);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState("");
    const [errorDesc, setErrorDesc] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (response) {
            setLoading(false);
        }
    }, [response, setLoading]);

    const submitQuery = (query) => {
        setResponse(null);
        setFeedbackSubmitted(false);
        setErrorDesc(null);
        setValidationErrors({});

        const validationResult = QueryDto.safeParse({query});
        if (!validationResult.success) {
            setValidationErrors(validationResult.error.format());
            return;
        }

        setLoading(true);

        wrapApiCallWithCsrf({
            url: '/namespace/' + id + '/query',
            method: 'POST',
            data: {
                query: query
            }
        }, (response) => {
            setResponse(response.data);
        }, (error) => {
            setErrorDesc(error.response.data.message);
            handleError(error, navigate);
        }, navigate);
    }

    const submitFeedback = (feedbackObj) => {
        // we don't care about the response here - this isn't critical and no feedback is given
        setFeedbackSubmitted(true);
        wrapApiCallWithCsrf({
            url: '/namespace/query/' + response.id + '/feedback',
            method: 'POST',
            data: {
                ...feedbackObj,
            }
        });
    }

    return (
        <div className={classes.container}>
            <div className={classes.inputContainer}>
                <input ref={inputArea} className={classes.input}/>
                <button onClick={() => submitQuery(inputArea.current.value)}>Go</button>
            </div>
            <div className={classes.errorContainer}>
                {validationErrors.query?._errors.map(error => <p className={classes.error}>{error}</p>)}
                {errorDesc ?
                    <p className={classes.error}>{{errorDesc}}</p> : ''}
            </div>
            <div className={classes.responseContainer}>
                {loading ?
                    <div className={classes.loadingContainer}>
                        {[...Array(8)].map((key) => {
                            return <div key={key} className={classes.loadingDot}></div>;
                        })}
                    </div> : ''
                }
                {response ?
                    <span>{response.response}</span> : ''
                }
                {response ?
                    <>
                        <div className={classes.feedbackContainer}>
                            {feedbackSubmitted ?
                                <span className={classes.feedbackHeader}>Thanks for your feedback.</span>
                                :
                                <>
                                    <span className={classes.feedbackHeader}>Was this helpful?</span>
                                    <div className={classes.feedbackOptions}>
                                        <span onClick={() => submitFeedback({positive: true})}>&#128077;</span>
                                        <span onClick={() => submitFeedback({positive: false})}>&#128078;</span>
                                    </div>
                                </>
                            }
                        </div>
                    </> : ''
                }
            </div>
        </div>
    )
        ;
};

export default QueryBox;