import React, {useEffect, useState} from 'react';
import classes from '../Form.module.css';
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {wrapApiCallWithCsrf} from "../../api/api";
import {PasswordDto, ResetRequestDto, User} from "../../api/schema";

const PasswordResetPage = () => {
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState(false);
    const [searchParams] = useSearchParams();
    const [validationErrors, setValidationErrors] = useState({});
    const [token, setToken] = useState(null);
    const [linkSent, setLinkSent] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setToken(searchParams.get('token'));
    }, [setToken, searchParams]);

    const handleSave = (event) => {
        event.preventDefault();
        setError(false);

        const validationResult = PasswordDto.safeParse(inputs);
        if (!validationResult.success) {
            setValidationErrors(validationResult.error.format());
            return;
        }

        wrapApiCallWithCsrf({
            url: '/auth/password/save',
            method: 'POST',
            data: {
                ...inputs,
                token: token
            }
        }, (response) => {
            navigate('/login')
        }, (error) => {
            setError(true);
        });
    }

    const handleRequest = (event) => {
        event.preventDefault();
        setError(false);
        setValidationErrors({});

        const validationResult = ResetRequestDto.safeParse(inputs);
        if (!validationResult.success) {
            setValidationErrors(validationResult.error.format());
            return;
        }

        wrapApiCallWithCsrf({
            url: '/auth/password',
            method: 'POST',
            params: inputs
        }, (response) => {
            setLinkSent(true);
        }, (error) => {
            setError(true);
        });
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    if (token)
        return (
            <div>
                <form className={classes.form} onSubmit={handleSave}>
                    <h1 className={classes.title}>What's your new password?</h1>
                    {error ?
                        <p className={classes.error}>An error occurred, please try again.</p> : ''}
                    <input className={classes.input} onChange={handleChange} name="password" type="password"
                           placeholder="New password"/>
                    {validationErrors.password?._errors.map(error => <p className={classes.error}>{error}</p>)}
                    <button className={classes.button}>Change password</button>
                    <p className={classes.otherForm}><Link to={'/login'}>Back to login.</Link></p>
                </form>
            </div>
        );
    else
        return (
            <div>
                <form className={classes.form} onSubmit={handleRequest}>
                    <h1 className={classes.title}>Reset password</h1>
                    {error ?
                        <p className={classes.error}>An error occurred, please check your email address and try
                            again.</p> : ''}
                    {linkSent ?
                        <p className={classes.error}>Please check your email for a link to reset your
                            password.</p> : ''}
                    <input className={classes.input} onChange={handleChange} name="email" type="text"
                           placeholder="Email address"/>
                    {validationErrors.email?._errors.map(error => <p className={classes.error}>{error}</p>)}
                    <button className={classes.button}>Request link</button>
                    <p className={classes.otherForm}><Link to={'/login'}>Back to login.</Link></p>
                </form>
            </div>
        );
};

export default PasswordResetPage;