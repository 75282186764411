import {Children, React, useRef} from 'react';
import classes from './IterList.module.css';
import EditNoteRow from "./EditNoteRow";

const NoteList = ({children, title, hideForm, namespace, setNamespace}) => {

    const formRef = useRef(null);

    return (
        <div className={classes.container}>
            <h2 className={classes.header}>{title}</h2>
            <form ref={formRef} onSubmit={(event) => event.preventDefault()}>
                <div className={classes.mobileContainer}>
                    {Children.map(children, child =>
                        child
                    )}
                </div>
                <table className={classes.table}>
                    <thead>
                    <tr>
                        <th className={classes.th}>Content</th>
                        <th className={classes.th}>Type</th>
                        <th className={classes.th}>Created</th>
                        <th className={classes.th}>Edit</th>
                        <th className={classes.th}>Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Children.map(children, child =>
                        child
                    )}
                    </tbody>
                    {!hideForm ?
                        <tfoot>
                        <EditNoteRow form={formRef} namespace={namespace} setNamespace={setNamespace}/>
                        </tfoot>
                        : ''}
                </table>
            </form>
        </div>
    );
};

export default NoteList;