import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import {handleError, wrapApiCallWithCsrf} from "../../api/api";
import classes from "../IterList.module.css";

const Namespace = ({namespace}) => {
    const navigate = useNavigate();
    const [deletePending, setDeletePending] = useState(false);
    const [deleted, setDeleted] = useState(false);

    const handleDelete = () => {
        setDeletePending(true);
    }

    const cancelDelete = () => {
        setDeletePending(false);
    }

    const affirmDelete = () => {
        wrapApiCallWithCsrf({
            method: 'DELETE',
            url: '/namespace/' + namespace.id
        }, (response) => {
            setDeleted(true);
        }, (error) => {
            handleError(error, navigate);
        }, navigate);
    }

    const handleClick = (event) => {
        if (event.target.tagName === 'tr')
            navigate('/app/' + namespace.id);
    }

    if (deleted)
        return '';
    else
        return (
            <>
                <tr onClick={handleClick}>
                    <td>{namespace.name}</td>
                    <td>{namespace.notes ? namespace.notes.length : 0}</td>
                    <td><Link to={'/app/' + namespace.id + '/edit'}>✎</Link></td>
                    <td onClick={handleDelete} style={{cursor: "pointer"}}>🗑</td>
                </tr>
                {
                    deletePending ?
                        <tr>
                            <td colSpan={2}>Are you sure you want to delete the namespace {namespace.name}?</td>
                            <td>
                                <button className={classes.button} onClick={affirmDelete}>Yes</button>
                            </td>
                            <td>
                                <button className={classes.button} onClick={cancelDelete}>No</button>
                            </td>
                        </tr> : ''
                }

                <div>
                    <p>{namespace.name}</p>
                    <div>
                        <span>{namespace.notes ? namespace.notes.length : 0} Notes</span>
                        <span> | </span>
                        <Link to={'/app/' + namespace.id + '/edit'}>Edit ✎</Link>
                        <span> |</span>
                        <span onClick={handleDelete} style={{cursor: "pointer"}}> Delete 🗑 </span>
                    </div>
                    {
                        deletePending ?
                            <div>
                                <p>Are you sure you want to delete the namespace {namespace.name}?</p>
                                <div>
                                    <button className={classes.button} onClick={affirmDelete}>Yes</button>
                                    <button className={classes.button} onClick={cancelDelete}>No</button>
                                </div>
                            </div> : ''
                    }
                </div>
            </>
        );
};

export default Namespace;