import React, {useContext, useEffect, useState} from 'react';
import {UserContext} from "../context/AuthContext";
import classes from './AccountDetails.module.css';
import NavigationRow from "./NavigationRow";
import NavigationButton from "./NavigationButton";
import ReactTimeAgo from "react-time-ago";
import {handleError, wrapApiCallWithCsrf} from "../api/api";
import {useNavigate} from "react-router-dom";
import Loading from "./Loading";
import {Capacitor} from "@capacitor/core";

const AccountDetails = () => {
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;
    const [subscription, setSubscription] = React.useState(userContext.subscription);
    // note that we can pull all these context features here without calling to request them because this is a child component so we know it's already been called
    const [betaFeatures, setBetaFeatures] = React.useState(null);
    const [doneStripeCheck, setDoneStripeCheck] = React.useState(false);
    const [error, setError] = useState();
    const [store, setStore] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (userContext.user)
            setBetaFeatures(userContext.user.betaOptIn);

        // if there's no subscription, we know we're going to get a 404
        // make another, slower call which includes a check with stripe
        if (userContext.subscription && !doneStripeCheck)
            wrapApiCallWithCsrf({
                url: '/subscriptions',
                method: 'GET',
                params: {cancelCheck: true}
            }, (response) => {
                setSubscription(response.data);
                setUserContext({...userContext, subscription: response.data});
                setDoneStripeCheck(true);
            }, (error) => {
                if (error?.response?.status === 500) {
                    alert("An error occurred, please contact support.");
                }
                handleError(error, navigate);
            });

        if (Capacitor.getPlatform() === 'ios') {
            // initialise native payments (used for restore button)
            // eslint-disable-next-line no-undef
            setStore(CdvPurchase.store);
        }
    }, [navigate, setSubscription, setUserContext, userContext, doneStripeCheck, setDoneStripeCheck]);

    const toggleBetaFeatures = () => {
        wrapApiCallWithCsrf({
            url: '/auth',
            method: 'PUT',
            data: {
                betaOptIn: !betaFeatures
            }
        }, (response) => {
            setBetaFeatures(response.data.betaOptIn);
        }, (error) => {
            handleError(error, navigate);
        });
    };

    const cancelSubscription = () => {
        setError(null);

        wrapApiCallWithCsrf({
                url: '/subscriptions/cancel',
                method: 'POST',
                data: subscription
            }, (response) => {
                setSubscription(subscription => ({...subscription, paymentDetail: "CANCEL_PENDING"}));
                setUserContext({...userContext, subscription: subscription});
            }, (error) => {
                setError(error.response.data.message);
            }
        )
    };

    const handleIAPRestore = () => {
        // todo (native payments) check our server and propagate update
        this.store.refresh();
    }

    // this request must be sent through the api methods in order to attach credentials
    const handleLogout = () => {
        wrapApiCallWithCsrf({
            url: '/logout',
            method: 'GET',
        }, (response) => {
            setUserContext({});
            navigate('/');
        }, (error) => {
            handleError(error, navigate);
        });
    }

    if (!doneStripeCheck)
        return <Loading/>
    else if (subscription && subscription.active)
        return (
            <>
                <div className={classes.container}>
                    <h1 className={classes.title}>Account details</h1>
                    {(subscription.paymentDetail && subscription.paymentDetail === "CANCEL_PENDING") ?
                        <>
                            <p className={classes.item}>Your {subscription.inTrial ? 'trial' : 'subscription'} will be cancelled automatically {<ReactTimeAgo
                                date={subscription.expiry} locale={navigator.language}/>}</p>
                            <p>If you wish to restart your subscription, please do so then.</p>
                        </> :
                        <>
                            <p className={classes.item}>Your {subscription.inTrial ? 'trial ends and your' : ''} subscription automatically
                                renews {subscription.type.displayName.toLowerCase()} for {subscription.type.currency === 'GBP' ? '£' : '$'}{subscription.type.price / 100.0}.</p>
                            <p className={classes.item}>The next renewal will be {<ReactTimeAgo
                                date={subscription.expiry} locale={navigator.language}/>}.</p>
                            <p className={classes.item}>To change your billing cycle, cancel your current subscription
                                then
                                purchase
                                a new one once it expires.</p>
                            <p className={classes.item}>Alternatively, please contact support.</p>
                            <NavigationRow>
                                <NavigationButton text={`Cancel ${subscription.inTrial ? 'trial' : 'subscription'}`}
                                                  handler={cancelSubscription}></NavigationButton>
                            </NavigationRow>
                            {error ? <p className={classes.error}>{error}</p> : ''}
                        </>
                    }
                </div>
                <div className={classes.container}>
                    <h1 className={classes.title}>Additional options</h1>
                    <NavigationRow>
                        <NavigationButton text={(betaFeatures ? "Disable" : "Enable") + " beta features"}
                                          buttonStyle={{background: 'linear-gradient(45deg, #aa4baa, #ccab8d)'}}
                                          handler={toggleBetaFeatures}></NavigationButton>
                    </NavigationRow>
                    <NavigationRow>
                        <NavigationButton text="Logout" handler={handleLogout}></NavigationButton>
                    </NavigationRow>
                    {Capacitor.getPlatform() === 'ios' &&
                        <NavigationRow>
                            <NavigationButton text="Restore Subscription" handler={handleIAPRestore}></NavigationButton>
                        </NavigationRow>}
                </div>
            </>
        );
};

export default AccountDetails;