// focused view for querying the namespace
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Hero from "../Hero";
import NavigationRow from "../NavigationRow";
import NavigationButton from "../NavigationButton";
import QueryBox from "../QueryBox";
import {checkAuth, handleError, wrapApiCallWithCsrf} from "../../api/api";
import TaskHeader from "../TaskHeader";
import {UserContext} from "../../context/AuthContext";

// hero good ___, ___ whats on your mind?
// back button
// getting started tips (hide this forever)
// text box, search button
// response field
// maybe we can include footer/ header but possibly not necessary

const NamespacePage = () => {
    let {id} = useParams();
    const [namespace, setNamespace] = useState({});
    const navigate = useNavigate();
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;

    // needs auth and subscription
    useEffect(() => {
        const apiGet = () => {
            wrapApiCallWithCsrf({
                url: '/namespace/' + id,
                method: 'GET'
            }, (response) => {
                setNamespace(response.data);
            }, (error) => {
                handleError(error, navigate);
            }, navigate);
        };

        async function checkAuthStates() {
            await checkAuth(userContext, setUserContext, navigate, true, true);

            apiGet();
        }

        checkAuthStates();
    }, [id, navigate, userContext, setUserContext, setNamespace]);

    return (
        <>
            <Hero/>
            <TaskHeader content={'Querying ' + (namespace ? namespace.name : '')} subtext={namespace?.notes?.length === 0 ? "This namespace doesn't have any notes yet. Responses won't be personalised for you." : null}/>
            <QueryBox id={id}/>
            <NavigationRow>
                <NavigationButton buttonStyle={{}} link={'/app'} text={'Back to Account'}/>
                <NavigationButton disabled={!namespace} buttonStyle={{}} link={'/app/' + namespace.id + '/edit'}
                                  text={'Edit ' + (namespace ? namespace.name : '')}/>
            </NavigationRow>
        </>
    );
};

export default NamespacePage;