import React from 'react';
import Story from "./Story";
import classes from './Stories.module.css';

const Stories = () => {
    return (
        <div className={classes.container}>
            <Story quote={"I've ditched my messy motivational OneNotes for Pragmate"} author={'JC'}/>
            <Story quote={"Tell Pragmate when you're about to give up, and you'll be pumped in moments"} author={'IM'}/>
            <Story quote={"It's satisfying finding motivation knowing I'll be reminded when I most need it"} author={'RH'}/>
        </div>
    );
};

export default Stories;