import React, {Children} from 'react';
import classes from "./IterList.module.css";

const NamespaceList = ({children, title}) => {

    return (
        <div className={classes.container}>
            <h2 className={classes.header}>{title}</h2>
            <div className={classes.mobileContainer}>
                {Children.map(children, child =>
                    child
                )}
            </div>
            <table className={classes.table}>
                <thead>
                <tr>
                    <th className={classes.th}>Name</th>
                    <th className={classes.th}>Notes</th>
                    <th className={classes.th}>Edit</th>
                    <th className={classes.th}>Delete</th>
                </tr>
                </thead>
                <tbody>
                {Children.map(children, child =>
                    child
                )}
                </tbody>
            </table>
        </div>
    );
};

export default NamespaceList;