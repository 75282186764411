import React, {useContext, useEffect, useState} from 'react';
import classes from './Hero.module.css';
import {UserContext} from "../context/AuthContext";

const Hero = () => {
    const [name, setName] = useState(null);
    const [splash, setSplash] = useState(null);
    const userContext = useContext(UserContext).userContext;
    const timeWord = new Date().getHours() < 12 ? 'morning' : new Date().getHours() < 18 ? 'afternoon' : 'evening';

    useEffect(() => {
        if (userContext.user)
            setName(userContext.user.name);

        const options = [
            (<h2 className={classes.text}>Good {timeWord}{name ? ', ' + name : ''}, <span className={classes.altColor}>what's on your mind?</span>
            </h2>),
            (<h2 className={classes.text}>Who's <span className={classes.altColor}>inspired</span> you
                today{name ? ', ' + name : ''}?</h2>),
        ];

        if (timeWord === 'morning')
            options.push(<h2 className={classes.text}>Ready for the day ahead<span
                className={classes.altColor}>{name ? ', ' + name : ''}?</span></h2>);
        else
            options.push(<h2 className={classes.text}>Prepared for tomorrow<span
                className={classes.altColor}>{name ? ', ' + name : ''}?</span></h2>);

        if (!splash)
            setSplash(options[Math.round(Math.random() * (options.length - 1))]);
    }, [name, setName, splash, timeWord, userContext]);

    return (
        <div className={classes.container}>
            {splash}
        </div>
    );
};

export default Hero;