import Axios from "axios";
import {Preferences} from '@capacitor/preferences'
import {Capacitor} from "@capacitor/core";

function getApiServer() {
    const origin = window.location.origin;

    if (origin.includes('localhost:8080')) {
        return 'http://localhost:8080';
    } else if (origin.includes('pragmate.org') || origin.includes('capacitor://localhost')) {
        return 'https://pragmate.org';
    }
}

export function getStripePK() {
    if (origin.includes('localhost:8080')) {
        return 'pk_test_51M4Bk5GbhHBzklDUEA8JWPjDXAyCCvmB49iNExeccvnpBcQO8W9oWR0Co7WbYnTmwWIXtzW7UsqkfjQzP7uT5og900KNJZRz6G';
    } else {
        return 'pk_live_51M4Bk5GbhHBzklDUCYnYc1jpDgDZEkJoHsMAK5ckr36b336i6g2sWzbMXjMjC8RrLpmOpIeSlDTr5LsUt1ErcGB000cJoC4eNH';
    }
}

export async function applyToRequest(requestConfig, navigate) {
    if (Capacitor.getPlatform() === 'ios') {
        const {value} = await Preferences.get({key: "at"});

        if (navigate && !value && !(
            requestConfig.url.includes('auth')
            || requestConfig.url.includes('native')
        )) {
            navigate('/login');
            return null;
        }

        if (value)
            requestConfig.headers = {
                ...requestConfig.headers,
                "Authorization": `Bearer ${value}`
            };
        return requestConfig;
    } else {
        return requestConfig;
    }
}

export function wrapApiCallWithCsrf(axiosRequestConfig, responseHandler, errorHandler, navigate) {
    axiosRequestConfig.url = getApiServer() + axiosRequestConfig.url;

    Axios.get(getApiServer() + '/csrf').then((response) => {

        // Apply the CSRF token to the request.
        if (!axiosRequestConfig.headers)
            axiosRequestConfig.headers = {};
        axiosRequestConfig.headers[response.data.headerName] = response.data.token;

        // For POST, PUT, DELETE, and PATCH requests, apply the CSRF token to the request body.
        if (!axiosRequestConfig.data)
            axiosRequestConfig.data = {};

        applyToRequest(axiosRequestConfig, navigate).then((config) => {
            Axios.request(config).then((response) => {
                if (response.request.responseURL.includes('/login') && !config.url.includes('/login')) {
                    navigate('/login');
                } else {
                    responseHandler(response);
                }
            }, errorHandler);
        });
    }, (error) => {
        alert('Failed to fetch from API, please refresh or try again later. If issues persist, contact support.');
    });
}

export async function wrapApiCallWithCsrfAsync(axiosRequestConfig) {
    axiosRequestConfig.url = getApiServer() + axiosRequestConfig.url;

    const csrfResponse = await Axios.get(getApiServer() + '/csrf');

    if (csrfResponse.status !== 200) {
        alert('Failed to fetch from API, please refresh or try again later. If issues persist, contact support.');
    } else {
        if (!axiosRequestConfig.headers)
            axiosRequestConfig.headers = {};
        axiosRequestConfig.headers[csrfResponse.data.headerName] = csrfResponse.data.token;
        await applyToRequest(axiosRequestConfig);

        try {
            return await Axios.request(axiosRequestConfig);
        } catch (err) {
            return err.response;
        }
    }
}

export function handleError(error, navigate) {
    if (!error.response) {
        alert('Error connecting to server, please check your internet connection and try again.');
        return;
    }
    switch (error.response.status) {
        case 400:
            if (error.response.data.message) {
                if (error.response.data.message === 'uk.co.xsc.pragmate.exception.NativeSessionTokenExpiredException') {
                    alert('Sorry, your session expired, please log in again.')
                    navigate('/login')
                }
            }
            if (error.response.data.errors) {
                let errors = [];
                for (const {message} of error.response.data.errors) {
                    errors.push(`${message}`);
                }
                alert(errors.join('\n'));
            }
            break;
        case 401:
            navigate('/login');
            break;
        case 403:
            break;
        case 404:
            navigate('/app');
            break;
        default:
            alert('An error occurred, please refresh or try again later. If issues persist, contact support.');
    }
    console.log("An error occurred: ", error);
}

/*
This function will always check for a login, and if there is one, will always check for a subscription.
Therefore, any subsequent checks can simply call context.user or context.subscription.
If needsLogin is true, or needsSub is true, a redirect may be performed.
 */
export async function checkAuth(context, setContext, navigate, needsLogin, needsSub) {
    // if we do not have the user, check by fetching
    if (!context.user) {
        const userResponse = await wrapApiCallWithCsrfAsync({
            url: '/auth',
            method: 'GET'
        });

        if (userResponse.data && userResponse.data.email) {
            setContext({...context, user: userResponse.data});
        } else {
            if (needsLogin) {
                navigate('/login');
                return;
            }
        }
    }

    // whether we already had the user or are only just getting it, if we don't have the subscription, check by fetching
    if (!context.subscription) {
        const subscriptionResponse = await wrapApiCallWithCsrfAsync({
            url: '/subscriptions',
            method: 'GET',
            params: {cancelCheck: false}
        });
        if (subscriptionResponse.status && subscriptionResponse.status === 200) {
            setContext({...context, subscription: subscriptionResponse.data});

            if (!(subscriptionResponse.data.active) && needsSub) {
                navigate('/subscribe');
            }
        } else {
            if (needsSub) {
                navigate('/subscribe');
            }
        }
    }
}