import React from 'react';
import classes from "./TipsTricks.module.css";

const TipsTricks = () => {
    return (
        <div className={classes.container}>
            <h2 className={classes.title}>Advice to get the best out of Pragmate</h2>
            <ol className={classes.tips}>
                <li>Keep notes to a <span className={classes.altColor}>singular topic</span>, but include some <span className={classes.altColor}>detail and context</span>.</li>
                <li>Tell Pragmate what you're struggling with or what you want to do when you need some drive.</li>
                {/*<li>Set up your workspace to feel like a warm fire so you feel comfortable.</li>*/}
            </ol>

            <h2 className={classes.title}>Nail the basics</h2>
            <ol className={classes.tips}>
                <li>A <span className={classes.altColor}>namespace</span> is your space, you can have one, or a hundred.</li>
                <li>Namespaces each have loads of <span className={classes.altColor}>notes</span>. These train the namespace to work for you.</li>
                <li>Each note has a <span className={classes.altColor}>type</span> to help Pragmate organise your thoughts.</li>
                <li>You'll get the best results filling up one namespace rather than having fewer notes across many namespaces.</li>
            </ol>

            <h2 className={classes.title}>What type of note is this?</h2>
            <ol className={classes.tips}>
                <li>A <span className={classes.altColor}>Facts: </span>what might you need to be reassured of as a ground truth?</li>
                <li>A <span className={classes.altColor}>Wants: </span>what are you working for personally?</li>
                <li>A <span className={classes.altColor}>Fears: </span>what fears may become a reality if you can't motivate yourself?</li>
                <li>A <span className={classes.altColor}>Achievement: </span>what great things have you done already?</li>
                <li>A <span className={classes.altColor}>Quote: </span>what do your role-models say that you want to remember?</li>
            </ol>
        </div>
    );
};

export default TipsTricks;