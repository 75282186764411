import React from 'react';
import ReactTimeAgo from "react-time-ago";
import {handleError, wrapApiCallWithCsrf} from "../../api/api";
import classes from "../IterList.module.css";
import {useNavigate} from "react-router-dom";
import EditNoteRow from "../EditNoteRow";

const Note = ({initNote/*, handleDelete, handleEdit*/}) => {
    const [deletePending, setDeletePending] = React.useState(false);
    const [deleted, setDeleted] = React.useState(false);
    const [editPending, setEditPending] = React.useState(false);
    const [note, setNote] = React.useState(initNote);
    const navigate = useNavigate();

    const handleDelete = () => {
        setDeletePending(true);
    }

    const affirmDelete = () => {
        wrapApiCallWithCsrf({
            method: 'DELETE',
            url: '/notes/' + note.id
        }, (response) => {
            setDeleted(true);
        }, (error) => {
            handleError(error, navigate);
        }, navigate);
    }

    const cancelDelete = () => {
        setDeletePending(false);
    }

    const handleEdit = () => {
        setEditPending(!editPending);
    }

    const typeArray = note.type.toLowerCase().split('');
    const type = typeArray.shift().toUpperCase() + typeArray.join('')

    if (deleted)
        return '';
    else
        return (
            <>
                <tr>
                    <td>{note.content}</td>
                    <td>{type}</td>
                    <td><ReactTimeAgo date={note.created} locale={navigator.language}/></td>
                    <td onClick={handleEdit} style={{cursor: "pointer"}}>✎</td>
                    <td onClick={handleDelete} style={{cursor: "pointer"}}>🗑</td>
                </tr>
                {
                    editPending ?
                        <EditNoteRow mobile={false} edit={true} setEditPending={setEditPending} note={note}
                                     setNote={setNote}/>
                        : ''
                }
                {
                    deletePending ?
                        <tr>
                            <td colSpan={3}>Are you sure you want to delete this note?</td>
                            <td>
                                <button className={classes.button} onClick={affirmDelete}>Yes</button>
                            </td>
                            <td>
                                <button className={classes.button} onClick={cancelDelete}>No</button>
                            </td>
                        </tr> : ''
                }

                <div>
                    <p>
                        {note.content}
                    </p>
                    <div>
                        <span>Type: <span>{type}</span></span>
                        <span> | </span>
                        <span>Created: <span><ReactTimeAgo date={note.created}
                                                           locale={navigator.language}/></span></span>
                        <span> |</span>
                        <span onClick={handleEdit} style={{cursor: "pointer"}}> Edit ✎ </span>
                        <span>|</span>
                        <span onClick={handleDelete} style={{cursor: "pointer"}}> Delete 🗑 </span>
                    </div>

                    {
                        editPending ?
                            <EditNoteRow mobile={true} edit={true} setEditPending={setEditPending} note={note}
                                         setNote={setNote}/>
                            : ''
                    }
                    {
                        deletePending ?
                            <div>
                                <p>Are you sure you want to delete this note?</p>
                                <div>
                                    <button className={classes.button} onClick={affirmDelete}>Yes</button>
                                    <button className={classes.button} onClick={cancelDelete}>No</button>
                                </div>
                            </div> : ''
                    }
                </div>
            </>
        );
};

export default Note;