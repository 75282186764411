import React, {useContext} from 'react';
import classes from './Footer.module.css';
import {Link, useNavigate} from "react-router-dom";
import {UserContext} from "../context/AuthContext";
import {handleError, wrapApiCallWithCsrf} from "../api/api";
import logo from '../media/logo192t.png';

const Footer = () => {
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;
    const navigate = useNavigate();

    // this request must be sent through the api methods in order to attach credentials
    const handleLogout = () => {
        wrapApiCallWithCsrf({
            url: '/logout',
            method: 'GET',
        }, (response) => {
            setUserContext({});
            navigate('/');
        }, (error) => {
            handleError(error, navigate);
        });
    }

    return (
        <div className={classes.container}>
            <div className={classes.columns}>
                <div className={classes.metaColumn}>
                    <Link to={"/"} className={classes.logoLink}>
                        <img src={logo} alt={"logo"}/>
                    </Link>
                    <p className={classes.metaName}>Pragmate</p>
                    <p className={classes.metaBrand}>An XSC Group Brand</p>
                </div>
                <div className={classes.linksColumn}>
                    <h4 className={classes.linksHeader}>Account</h4>
                    {userContext.user ? <>
                        <Link to={"/subscribe"} className={classes.link}>Subscribe</Link>
                        {/*this is not a link as a GET request has to be sent to log out*/}
                        <span onClick={handleLogout} className={classes.link}>Logout</span>
                        <Link to={"/app"} className={classes.link}>My Account</Link>
                    </> : <>
                        <Link to={"/login"} className={classes.link}>Login</Link>
                        <Link to={"/signup"} className={classes.link}>Sign Up</Link>
                    </>}
                </div>
                <div className={classes.linksColumn}>
                    <h4 className={classes.linksHeader}>Useful Links</h4>
                    <Link to={"/"} className={classes.link}>Home</Link>
                    <Link to={"/subscribe"} className={classes.link}>Subscribe</Link>
                    <Link to={"/privacy"} className={classes.link}>Privacy</Link>
                    <Link to={"/support"} className={classes.link}>Support</Link>
                </div>
            </div>
            <div className={classes.copyright}>
                <p>Copyright &copy; {new Date().getFullYear()} Pragmate</p>
                <p>All rights reserved.</p>
            </div>
        </div>
    );
};

export default Footer;