import React, {useState} from 'react';
import classes from './Form.module.css';
import {Link, useNavigate} from "react-router-dom";
import {handleError, wrapApiCallWithCsrf} from "../api/api";
import {Capacitor} from "@capacitor/core";
import {Preferences} from "@capacitor/preferences";

const LoginForm = () => {
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const [invalid, setInvalid] = useState(false);

    Preferences.set({key: "at", value: null});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setInvalid(false);
        setError(false);

        if (Capacitor.getPlatform() === 'ios') {
            wrapApiCallWithCsrf({
                url: '/login/native',
                method: 'POST',
                params: inputs,
            }, (response) => {
                Preferences.set({key: "at", value: response.data.token}).then(async () => {
                    navigate('/app');
                });
            }, (error) => {
                setError(true);
                handleError(error, navigate);
            }, navigate);
        } else {
            wrapApiCallWithCsrf({
                url: '/login',
                method: 'POST',
                params: inputs,
            }, (response) => {
                if (response.request.responseURL.includes('error'))
                    setInvalid(true)
                else
                    navigate('/app')
            }, (error) => {
                setError(true);
                handleError(error, navigate);
            }, navigate);
        }
    }
    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <h1 className={classes.title}>We need you to log in for that</h1>
            {error ?
                <p className={classes.error}>An error occurred, please try again later.</p> : ''}
            {invalid ?
                <p className={classes.error}>Invalid credentials provided.</p> : ''}
            <input className={classes.input} onChange={handleChange} name="username" type="text" placeholder="Email"/>
            <input className={classes.input} onChange={handleChange} name="password" type="password"
                   placeholder="Password"/>
            <button className={classes.button}>Login</button>
            <p className={classes.otherForm}>Need an account? <Link to={'/signup'}>Click here to create one.</Link></p>
            <p className={classes.otherForm}><Link to={'/reset'}>Forgot password?</Link></p>
        </form>
    );
};

export default LoginForm;