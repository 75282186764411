import React, {useState} from 'react';
import classes from "./Form.module.css";
import {handleError, wrapApiCallWithCsrf} from "../api/api";
import {Link, useNavigate} from "react-router-dom";
import {User} from "../api/schema";
import {Capacitor} from "@capacitor/core";
import {Preferences} from "@capacitor/preferences";

const SignupForm = () => {
    const [inputs, setInputs] = useState({});
    const navigate = useNavigate();
    const [errorDesc, setErrorDesc] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorDesc(null);

        const validationResult = User.safeParse(inputs);
        if (!validationResult.success) {
            setValidationErrors(validationResult.error.format());
            return;
        }

        if (Capacitor.getPlatform() === 'ios') {
            wrapApiCallWithCsrf({
                url: '/register/native',
                method: 'POST',
                data: inputs,
            }, (response) => {
                Preferences.set({key: "at", value: response.data.token}).then(async () => {
                    navigate('/app');
                });
            }, (error) => {
                setErrorDesc(error.response.data.message);
            }, navigate);
        } else {
            wrapApiCallWithCsrf({
                url: '/register',
                method: 'POST',
                data: inputs
            }, (response) => {
                navigate('/app')
            }, (error) => {
                setErrorDesc(error.response.data.message);
                handleError(error, navigate);
            }, navigate);
        }
    }

    return (
        <form className={classes.form} onSubmit={handleSubmit}>
            <h1 className={classes.title}>We need a few details</h1>
            {errorDesc ?
                <p className={classes.error}>{{errorDesc}}</p> : ''}
            <input className={classes.input} onChange={handleChange} name="name" type="text" placeholder="First Name"/>
            {validationErrors.name?._errors.map(error => <p className={classes.error}>{error}</p>)}

            <input className={classes.input} onChange={handleChange} name="email" type="text" placeholder="Email"/>
            {validationErrors.email?._errors.map(error => <p className={classes.error}>{error}</p>)}

            <input className={classes.input} onChange={handleChange} name="password" type="password"
                   placeholder="Password"/>
            {validationErrors.password?._errors.map(error => <p className={classes.error}>{error}</p>)}

            <button className={classes.button}>Sign Up</button>
            <p className={classes.otherForm}>Already have an account? <Link to={'/login'}>Click here to login.</Link>
            </p>
        </form>
    );
};

export default SignupForm;