// will take in paid traffic, with some info about the campaign
import React from 'react';
import Stories from "../Stories";
import HowItWorks from "../HowItWorks";
import Pricing from "../Pricing";
import Landing from "../Landing";

// header (may or may not extract)
    // landing / hero
    // stories
    // how it works
    // pricing
    // footer (may or may not extract)

const CampaignLanding = () => {

    // no login required for this page

    return (
        <>
            <Landing campaign={{slogan: "TODO"}}/>
            {/*todo turo campaign and joel campaign, can probably do a very relatable video... or scrolling tiktok, hear something motivational then quit
            when i started, i realised it could be so much faster if only i knew what results were to come...*/}
            <Stories/>
            <HowItWorks/>
            <Pricing/>
        </>
    );
};

export default CampaignLanding;