import React from 'react';
import classes from './Header.module.css';
import {Link} from "react-router-dom";
import logo from '../media/logo192t.png';

const Header = () => {
    return (
        <div className={classes.container}>
            <div className={classes.logoContainer}>
                <Link to={'/'}><img src={logo} alt={"logo"}/></Link>
            </div>
            <div className={classes.linksContainer}>
                <span className={classes.pragmate}>Pragmate</span>
            </div>
        </div>
    );
};

export default Header;