import {z} from "zod";

export const Namespace = z.object({
    name: z.string({required_error: "Name is required"}).min(1, "Name is required").max(50, "Name cannot be longer than 50 characters"),

});

export const Note = z.object({
    content: z.string({required_error: "Note content is required"}).min(1, "Note content is required").max(500, "Note content cannot be longer than 500 characters"),
    type: z.enum(["FACT", "WANT", "FEAR", "ACHIEVEMENT", "QUOTE"]),
});

export const User = z.object({
    // name: z.string({required_error: "Name is required"}).min(1, "Name is required").max(50, "Name cannot be longer than 50 characters"),
    email: z.string({required_error: "Email is required"}).email("Email must be valid").max(100, "Email cannot be longer than 100 characters"),
    password: z.string({required_error: "Password is required"}).min(8, "Password must be at least 8 characters").max(80, "Password must be at most 80 characters"),
    name: z.string({required_error: "Name is required"}).min(1, "Name must be at least 2 characters").max(30, "Name must be at most 30 characters"),
});

export const PasswordDto = z.object({
    password: z.string({required_error: "New password is required"}).min(8, "Password must be at least 8 characters").max(80, "Password must be at most 80 characters"),
});

export const ResetRequestDto = z.object({
    email: z.string({required_error: "Email is required"}).email("Email must be valid").max(100, "Email cannot be longer than 100 characters"),
});

export const QueryDto = z.object({
    query: z.string({required_error: "You cannot submit a blank query"}).min(1, "You cannot submit a blank query").max(500, "Query cannot be longer than 500 characters")
});