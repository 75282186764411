import React from 'react';
import classes from './Pricing.module.css';
import PricingTier from "./PricingTier";

const Pricing = ({selectCallback, selected /*used on the subscribe page*/}) => {
    const [currency, setCurrency] = React.useState('USD');

    const selectCurrency = (event) => {
        setCurrency(event.target.value);
    };

    return (
        <div className={classes.pricingContainer}>
            <h2 className={classes.title}>Pricing</h2>
            <p className={classes.subtext}>Try free for 7 days</p>
            <select className={classes.selector} onChange={selectCurrency}>
                <option value="USD" selected={currency === 'USD'}>USD</option>
                <option value="GBP" selected={currency === 'GBP'}>GBP</option>
            </select>
            <div className={classes.tierContainer}>
                {currency === 'USD' ?
                    <>
                        <PricingTier currency="$" typeIdentifier="MONTHLY_USD" price={"5.49"} subheading={"Monthly"}
                                     selectCallback={selectCallback ? selectCallback : null} selected={selected}/>
                        <PricingTier currency="$" typeIdentifier="ANNUALLY_USD" price={"49.99"} subheading={"Annually"}
                                     selectCallback={selectCallback ? selectCallback : null} selected={selected}/>
                    </>
                    :
                    <>
                        <PricingTier currency="£" typeIdentifier="MONTHLY_GBP" price={"4.49"} subheading={"Monthly"}
                                     selectCallback={selectCallback ? selectCallback : null} selected={selected}/>
                        <PricingTier currency="£" typeIdentifier="ANNUALLY_GBP" price={"39.99"} subheading={"Annually"}
                                     selectCallback={selectCallback ? selectCallback : null} selected={selected}/>
                    </>
                }
            </div>
        </div>
    );
};

export default Pricing;