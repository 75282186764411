import React, {useEffect} from 'react';
import NavigationRow from "../NavigationRow";
import NavigationButton from "../NavigationButton";
import Stories from "../Stories";
import HowItWorks from "../HowItWorks";
import Pricing from "../Pricing";
import Landing from "../Landing";
import {Preferences} from "@capacitor/preferences";
import {Capacitor} from "@capacitor/core";
import {useNavigate} from "react-router-dom";

const HomePage = () => {

    const navigate = useNavigate();

    // header (may or may not extract)
    // landing
    // go to tool
    // stories
    // how it works
    // pricing
    // footer (may or may not extract)

    // In the app the homepage just gets in the way of singing in quickly - obviously campaigns won't go to app
    useEffect(() => {
        if (Capacitor.getPlatform() === 'ios')
            navigate('/app');
    }, [navigate]);

    return (
        // does not need login or sub
        <>
            <Landing/>
            <NavigationRow>
                <NavigationButton text={'Go to App'} buttonStyle={{}} link={'/app'}/>
            </NavigationRow>
            <Stories/>
            <HowItWorks/>
            <Pricing/>
        </>
    );
};

export default HomePage;