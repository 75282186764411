import React, {useEffect, useState} from 'react';
import classes from "./Form.module.css";
import {useNavigate} from "react-router-dom";
import {wrapApiCallWithCsrf} from "../api/api";
import {Namespace} from "../api/schema";

const NamespaceForm = ({edit, addNamespace, namespace}) => {
    const [inputs, setInputs] = useState({});
    const [errorDesc, setErrorDesc] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (namespace)
            setInputs(namespace);
    }, [namespace]);

    const handleSubmit = (event) => {
        event.preventDefault();
        setErrorDesc(null);
        setValidationErrors({});

        const validationResult = Namespace.safeParse(inputs);
        if (!validationResult.success) {
            setValidationErrors(validationResult.error.format());
            return;
        }

        if (edit)
            wrapApiCallWithCsrf({
                url: '/namespace/' + namespace.id,
                method: 'PUT',
                data: inputs
            }, (response) => {
                addNamespace(response.data);
            }, (error) => {
                setErrorDesc(error.response.data.message);
            }, navigate);
        else
            wrapApiCallWithCsrf({
                url: '/namespace',
                method: 'POST',
                data: inputs
            }, (response) => {
                addNamespace(response.data);
            }, (error) => {
                setErrorDesc(error.response.data.message);
            }, navigate);
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    return (
        <>
            <form className={classes.form} onSubmit={handleSubmit}>
                <h1 className={classes.title}>{edit ? "Update namespace details" : "Create new namespace"}</h1>
                {errorDesc ?
                    <p className={classes.error}>{{errorDesc}}</p> : ''}
                {edit ?
                    <input onChange={handleChange} className={classes.input} name="name" type="text" placeholder="Name"
                           value={inputs.name}/>
                    : <input onChange={handleChange} className={classes.input} name="name" type="text"
                             placeholder="Name"/>
                }
                {validationErrors.name?._errors.map(error => <p className={classes.error}>{error}</p>)}
                <input type={"submit"} value={edit ? 'Update' : 'Save'} className={classes.button}/>
            </form>
        </>
    );
};

export default NamespaceForm;