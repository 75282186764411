import React from 'react';
import classes from './Landing.module.css';

const Landing = ({campaign}) => {
    return (
        <div className={classes.container}>
            <p className={classes.welcome}>Welcome to <span className={classes.pragmate}>Pragmate</span></p>
            <p className={classes.slogan}>{
                campaign ? campaign.slogan : "A new way to start your day. Try free now for 7 days."
            }</p>
        </div>
    );
};

export default Landing;