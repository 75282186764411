import React from 'react';
import {useNavigate} from "react-router-dom";
import classes from './NavigationButton.module.css';

const NavigationButton = ({link, text, buttonStyle, handler, disabled}) => {
    const navigate = useNavigate();

    function handleNavigate() {
        navigate(link);
    }

    return (
        <button disabled={disabled} className={classes.button} onClick={handler ? handler : handleNavigate} style={buttonStyle}>
            {text}
        </button>
    );
};

export default NavigationButton;