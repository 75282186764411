// view for mutating the notes etc.
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Hero from "../Hero";
import NavigationRow from "../NavigationRow";
import NavigationButton from "../NavigationButton";
import NoteList from "../NoteList";
import Note from "../iter/Note";
import {checkAuth, handleError, wrapApiCallWithCsrf} from "../../api/api";
import NamespaceForm from "../NamespaceForm";
import TaskHeader from "../TaskHeader";
import {UserContext} from "../../context/AuthContext";

// hero good ___, ___ whats on your mind? ... could be queried... time of day, picture e.g., get a warm fireplace for x in winter
// query button, back to account button
// getting started tips (hide this forever)
// notes list (needs pagination)
// review these notes section
// add new notes


const EditNamespace = () => {
    const [namespace, setNamespace] = useState({});
    const [notesToReview, setNotesToReview] = useState({notes:[]});
    const navigate = useNavigate();
    let {id} = useParams();
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;

    // needs auth and subscription
    useEffect(() => {
        const apiGet = () => {
            wrapApiCallWithCsrf({
                url: '/namespace/' + id,
                method: 'GET'
            }, (response) => {
                setNamespace(response.data);
            }, (error) => {
                handleError(error, navigate);
            }, navigate);

            wrapApiCallWithCsrf({
                url: '/namespace/' + id + '/review',
                method: 'GET'
            }, (response) => {
                setNotesToReview({notes: response.data});
            }, (error) => {
                handleError(error, navigate);
            });
        };

        async function checkAuthStates() {
            await checkAuth(userContext, setUserContext, navigate, true, true);

            apiGet();
        }

        checkAuthStates();
    }, [id, navigate, userContext, setUserContext, setNamespace, setNotesToReview]);

    const saveNamespace = (namespace) => {
        setNamespace(namespace);
    }

    return (
        <>
            <Hero/>
            <TaskHeader content={'Editing ' + (namespace?.name ? namespace.name : '')}/>
            <NavigationRow>
                <NavigationButton buttonStyle={{}} text={'Back to Account'} link={'/app'}/>
                <NavigationButton disabled={!namespace} buttonStyle={{}} text={'Use ' + (namespace?.name ? namespace.name : '')}
                                  link={'/app/' + namespace.id}/>
            </NavigationRow>
            <NoteList title={'All Notes'} namespace={namespace} setNamespace={setNamespace}>
                {namespace.notes ? namespace.notes.map((note) => {
                    return <Note key={note.id} initNote={note}/>
                }) : ''}
            </NoteList>
            <NoteList title={'Notes to Review'} hideForm={true}>
                {notesToReview.notes ? notesToReview.notes.map((note) => {
                    return <Note key={note.id} initNote={note}/>
                }) : ''}
            </NoteList>
            <NamespaceForm edit={true} namespace={namespace} addNamespace={saveNamespace}/>
        </>
    );
};

export default EditNamespace;