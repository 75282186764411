import React, {useEffect, useRef, useState} from 'react';
import classes from "./IterList.module.css";
import {useNavigate} from "react-router-dom";
import {handleError, wrapApiCallWithCsrf} from "../api/api";
import {Note} from "../api/schema";

const EditNoteRow = ({namespace, note, setNote, setNamespace, edit, setEditPending, mobile}) => {

    const [inputs, setInputs] = useState({});
    const [errorDesc, setErrorDesc] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const navigate = useNavigate();
    const taRef = useRef(null);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    };

    useEffect(() => {
        if (edit) {
            setInputs({
                content: note.content,
                type: note.type
            });
        }
    }, [edit, note, setInputs]);


    const handleClick = (event) => {
        event.preventDefault();
        setErrorDesc(null);
        setValidationErrors({});

        const validationResult = Note.safeParse(inputs);
        if (!validationResult.success) {
            setValidationErrors(validationResult.error.format());
            return;
        }

        if (edit) {
            wrapApiCallWithCsrf({
                url: '/notes/' + note.id,
                method: 'PUT',
                data: inputs
            }, (response) => {
                setNote(response.data);
                setEditPending(false);
            }, (error) => {
                handleError(error, navigate);
            });
        } else {
            wrapApiCallWithCsrf({
                url: '/notes',
                method: 'POST',
                data: {...inputs, namespace: {id: namespace.id}}
            }, (response) => {
                setNamespace({...namespace, notes: [...namespace.notes, response.data]});
                taRef.current.value = '';
            }, (error) => {
                setErrorDesc(error.response.data.message);
                handleError(error, navigate)
            });
        }
    };

    if (mobile)
        return (
            <div className={classes.mobileEditContainer}>
                <textarea ref={taRef} className={classes.input} name={"content"} placeholder={"Note"}
                          onChange={handleChange} defaultValue={note ? note.content : ''}/>
                {validationErrors.content?._errors.map(error => <p className={classes.error}>{error}</p>)}
                <div>
                    <select className={classes.select} name={"type"} onChange={handleChange}
                            defaultValue={note ? note.type : "Select"}>
                        {!edit ? <option disabled value={"Select"}>Select</option> : ''}
                        <option value={"FACT"}>Fact</option>
                        <option value={"WANT"}>Want</option>
                        <option value={"FEAR"}>Fear</option>
                        <option value={"ACHIEVEMENT"}>Achievement</option>
                        <option value={"QUOTE"}>Quote</option>
                    </select>
                    {validationErrors.type?._errors.map(error => <p className={classes.error}>{error}</p>)}
                    <button onClick={handleClick} className={classes.button} type={"submit"}>Save</button>
                    {errorDesc ?
                        <p className={classes.error}>{{errorDesc}}</p> : ''}
                </div>
            </div>
        );
    else
        return (
            <tr className={classes.tfoot}>
                <td>
                <textarea ref={taRef} className={classes.input} name={"content"} placeholder={"Note"}
                          onChange={handleChange} defaultValue={note ? note.content : ''}/>
                    {validationErrors.content?._errors.map(error => <p className={classes.error}>{error}</p>)}
                </td>
                <td>
                    <select className={classes.select} name={"type"} onChange={handleChange}
                            defaultValue={note ? note.type : "Select"}>
                        {!edit ? <option disabled value={"Select"}>Select</option> : ''}
                        <option value={"FACT"}>Fact</option>
                        <option value={"WANT"}>Want</option>
                        <option value={"FEAR"}>Fear</option>
                        <option value={"ACHIEVEMENT"}>Achievement</option>
                        <option value={"QUOTE"}>Quote</option>
                    </select>
                    {validationErrors.type?._errors.map(error => <p className={classes.error}>{error}</p>)}
                </td>
                <td colSpan={3}>
                    <button onClick={handleClick} className={classes.button} type={"submit"}>Save</button>
                    {errorDesc ?
                        <p className={classes.error}>{{errorDesc}}</p> : ''}
                </td>
            </tr>
        );
};

export default EditNoteRow;