import React, {useContext, useEffect, useState} from 'react';
import {checkAuth, getStripePK, handleError, wrapApiCallWithCsrf} from "../../api/api";
import {useNavigate, useSearchParams} from "react-router-dom";
import Pricing from "../Pricing";
import classes from "./SubscribePage.module.css";
import {Elements, useStripe} from "@stripe/react-stripe-js";
import Checkout from "../Checkout";
import {loadStripe} from "@stripe/stripe-js";
import {UserContext} from "../../context/AuthContext";
import {Capacitor} from "@capacitor/core";
import CheckoutCompletion from "../CheckoutCompletion";


const SubscribePage = () => {
    const [subscription, setSubscription] = useState(null);
    const [flowCompleted, setFlowCompleted] = useState(false);
    const [creatingSubscription, setCreatingSubscription] = useState(false);
    const [selectTypeError, setSelectTypeError] = useState(false);
    const navigate = useNavigate();
    const [subscriptionType, setSubscriptionType] = useState(null);
    const [searchParams] = useSearchParams();
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;
    const [store, setStore] = useState(null);

    // needs auth does not need subscription
    // and ditch if sub exists!
    useEffect(() => {
        async function checkAuthStates() {
            await checkAuth(userContext, setUserContext, navigate, true, false);

            if (userContext.subscription && userContext.subscription.active) {
                navigate('/app');
            }
        }

        checkAuthStates();

        if (Capacitor.getPlatform() === 'ios') {
            // initialise native payments

            // eslint-disable-next-line no-undef
            const cdvStore = CdvPurchase.store;

            setStore(cdvStore);

            // cdvStore.register({
            // todo (native payments) get registration details from apple
            // });

            cdvStore.initialize();

            cdvStore.when('product').approved((p) => {
                // check id and update state/deliverable
                // not sure how to update on backend... webhooks from apple should solve this

                return p.verify();
            });
            // there is a way to query individual products as well


            // cdvStore.readyState(() => {
            // here we can retrieve the actual products
            // appears to have been replaced with something else - check v13 docs
            // });

            setStore(cdvStore);
        }
    }, [navigate, userContext, setUserContext, setStore]);

    const purchase = (product) => {
        store.order(product).then((p) => {
            // in progress
        }, (e) => {
            // handle error
        });
    }

    const stripePromise = loadStripe(getStripePK());

    useEffect(() => {
        const subTypeParam = searchParams.get('subscriptionType');
        if (subTypeParam) {
            setSubscriptionType(subTypeParam);
        }

        if (searchParams.get("completed") !== null) {
            // show interstitial
            setCreatingSubscription(true);
        }
    }, [searchParams, setSubscriptionType]);

    const requestSubscription = (event) => {

        if (!subscriptionType) {
            setSelectTypeError(true);
            return;
        }
        setSelectTypeError(false);


        if (Capacitor.getPlatform() === 'ios') {
            // handle native payments

            purchase(null);
            // todo (native payments) impl https://devdactic.com/ionic-in-app-purchase-capacitor
        } else {
            // get a session so we can save some payment details
            wrapApiCallWithCsrf({
                url: '/subscriptions/create/session',
                method: 'POST',
                params: {
                    subscriptionType: subscriptionType
                }
            }, (response) => {
                setSubscription(response.data);
            }, (error) => {
                // detect conflict and redirect to account
                if (error.response && error.response.status === 409) {
                    navigate('/app');
                } else {
                    handleError(error, navigate);
                }
            });
        }
    }

    // these two phases are contained in the CheckoutCompletion component
    if ((creatingSubscription || flowCompleted)) {
        return (
            <Elements stripe={stripePromise}>
                <CheckoutCompletion flowCompleted={flowCompleted} setFlowCompleted={setFlowCompleted}
                                    creatingSubscription={creatingSubscription}
                                    setCreatingSubscription={setCreatingSubscription}/>
            </Elements>
        );
    } else if (subscription) {
        // this phase is not valid for native
        return (
            <Elements stripe={stripePromise} options={{
                clientSecret: subscription.stripeClientSecretId, appearance: {
                    theme: 'flat',
                    labels: 'floating',
                    variables: {
                        colorText: '#ffffff',
                        fontSizeBase: '14px',
                    },
                    rules: {
                        '.Input': {
                            border: 'none',
                            borderRadius: '5px',
                            backgroundColor: 'rgba(255, 255, 255, 0.1)', /* Slight background for input fields */
                            color: '#ffffff',
                            outline: 'none',
                        },
                        '.Tab': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            color: '#ffffff',
                            borderRadius: '5px',
                            transition: 'background 0.3s',
                            outline: 'none',
                        },
                        '.Tab:focus': {
                            outline: 'none',
                            boxShadow: 'none'
                        },
                        '.Tab--selected:focus': {
                            outline: 'none',
                            boxShadow: 'none'
                        },
                        '.Tab:active': {
                            outline: 'none',
                            boxShadow: 'none'
                        },
                        '.Input:focus': {
                            outline: 'none',
                            boxShadow: 'none'
                        },
                        '.Tab--selected': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)'
                        },
                        '.Label': {
                            color: '#ffffff'
                        },
                        '.Block': {
                            backgroundColor: '#202020'
                        }
                    }
                }
            }
            }>
                <Checkout subscription={subscription}/>
            </Elements>
        );
    } else {
        // this phase is valid for native
        return (
            <>
                <div className={classes.introContainer}>
                    <h1>Unlock the benefits of <span className={classes.altColor}>Pragmate</span> with a free trial</h1>
                </div>
                <div className={classes.pricingContainer}>
                    <h1 className={classes.title}>Decide how to join</h1>
                    <Pricing selectCallback={setSubscriptionType}
                             selected={subscriptionType ? subscriptionType : null}/>
                    {selectTypeError ?
                        <p className={classes.errorMessage}>Please select a subscription plan.</p> : ''}
                    <button className={classes.selectPriceButton} onClick={requestSubscription}>Continue to free trial
                    </button>
                </div>
            </>
        );
    }
};

export default SubscribePage;