import React from 'react';
import classes from  './SupportPage.module.css';

const SupportPage = () => {
    return (
        <div className={classes.container}>
            <h1 className={classes.title}>Need to report a bug, want to request a feature, or just have a burning question?</h1>

            <p className={classes.item}>Email us <a className={classes.contact} href={"mailto:support@pragmate.org*"}>support@pragmate.org</a></p>
            {/*<p className={classes.item}>Text us <a className={classes.contact} href={"tel:+17868768442"}>+1 (786) 876 8442</a></p>*/}
        </div>
    );
};

export default SupportPage;