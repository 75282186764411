import React from 'react';
import {BrowserRouter, MemoryRouter, Navigate, Route, Routes} from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import CampaignLanding from "./components/pages/CampaignLanding";
import Account from "./components/pages/Account";
import NamespacePage from "./components/pages/NamespacePage";
import EditNamespace from "./components/pages/EditNamespace";
import MasterLayout from "./components/MasterLayout";
import LoginPage from "./components/pages/LoginPage";
import SignUpPage from "./components/pages/SignUpPage";
import SubscribePage from "./components/pages/SubscribePage";
import SupportPage from "./components/pages/SupportPage";
import PasswordResetPage from "./components/pages/PasswordResetPage";
import {Capacitor} from "@capacitor/core";
import PrivacyPage from "./components/pages/PrivacyPage";

const App = () => {

    const wrapWithRouter = (child) => {
        if (Capacitor.getPlatform() === 'ios') {
            return (<MemoryRouter>{child}</MemoryRouter>);
        } else {
            return <BrowserRouter>{child}</BrowserRouter>;
        }
    }

    return wrapWithRouter(
        <MasterLayout>
            <Routes>
                <Route path="/" element={<HomePage/>}></Route>
                <Route path="/login" element={<LoginPage/>}></Route>
                <Route path="/signup" element={<SignUpPage/>}></Route>
                <Route path="/reset" element={<PasswordResetPage/>}></Route>
                <Route path="/subscribe" element={<SubscribePage/>}></Route>
                <Route path="/support" element={<SupportPage/>}></Route>
                <Route path="/go" element={<CampaignLanding/>}></Route>
                <Route path="/app" element={<Account/>}></Route>
                <Route path="/app/:id" element={<NamespacePage/>}></Route>
                <Route path="/app/:id/edit" element={<EditNamespace/>}></Route>
                <Route path="/privacy" element={<PrivacyPage/>}></Route>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>
        </MasterLayout>
    );
};

export default App;