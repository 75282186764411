// displays namespaces
import React, {useContext, useEffect, useState} from 'react';
import Hero from "../Hero";
import NavigationRow from "../NavigationRow";
import NavigationButton from "../NavigationButton";
import NamespaceList from "../NamespaceList";
import AccountDetails from "../AccountDetails";
import TipsTricks from "../TipsTricks";
import Namespace from "../iter/Namespace";
import {checkAuth, handleError, wrapApiCallWithCsrf, wrapApiCallWithCsrfAsync} from "../../api/api";
import {useLocation, useNavigate} from "react-router-dom";
import NamespaceForm from "../NamespaceForm";
import {UserContext} from "../../context/AuthContext";
import classes from "./Account.module.css";
import Loading from "../Loading";

// hero good ___, ___ whats on your mind?
// go to newest namespace (recommended in fullness of time)
// getting started tips (hide this forever)
// my namespaces
// create namespace (expands form)
// my details / billing
// tips and tricks

const Account = () => {
        const [namespaces, setNamespaces] = useState([]);
        const [showForm, setShowForm] = useState(false);
        const [subscription, setSubscription] = useState(null);
        const [statusMessage, setStatusMessage] = useState(null);
        const [loading, setLoading] = useState(true);
        const navigate = useNavigate();
        const location = useLocation();
        const userContext = useContext(UserContext).userContext;
        const setUserContext = useContext(UserContext).setUserContext;

        useEffect(() => {
            // needs to be logged in, optionally with subscription
            // a second call to the api is not required here because if the subscription is not active it falls back to stripe
            async function setAuthStates() {
                await checkAuth(userContext, setUserContext, navigate, true, true);
                if (userContext.subscription) {
                    setSubscription(userContext.subscription);
                    if (!userContext.subscription.active) {
                        if (!userContext.subscription.paymentDetail)
                            setStatusMessage("An unknown error occurred. Please contact support.");
                        else
                            switch (userContext.subscription.paymentDetail) {
                                case "REJECTED":
                                    setStatusMessage("Your payment didn't complete. Please try again.");
                                    break;
                                case "PROCESSING":
                                    setStatusMessage("Your payment is still processing. Please wait a few minutes and refresh the page.");
                                    break;
                                case "UNKNOWN":
                                    setStatusMessage("An unknown error occurred. Please contact support.");
                                    break;
                                case "EXPIRED":
                                    setStatusMessage("Your subscription has expired.");
                                    break;
                                default:
                                    setStatusMessage("An unknown error occurred. Please contact support.");
                            }
                    } else {
                        // subscription is active, bypass status message
                        setStatusMessage(null);
                    }
                } else {
                    setStatusMessage(null);
                }
            }

            // prevent repeated execution
            if (!subscription) {
                setAuthStates();
            }
            setLoading(false);
        }, [setStatusMessage, userContext, setUserContext, setSubscription, navigate, subscription]);

        useEffect(() => {
            if (!statusMessage && subscription && subscription.active)
                wrapApiCallWithCsrf({
                    url: '/namespace/all',
                    method: 'GET'
                }, (response) => {
                    setNamespaces(response.data);
                }, (error) => {
                    handleError(error, navigate);
                }, navigate);
        }, [setNamespaces, location, navigate, statusMessage, subscription]);

        const toggleForm = () => {
            setShowForm(!showForm);
        };

        const addNamespace = (namespace) => {
            setNamespaces(namespaces => [...namespaces, namespace]);
            toggleForm();
        };

        if (loading)
            return <Loading/>
        if (statusMessage)
            return (
                <div className={classes.statusDisplay}>
                    <p>{statusMessage}</p>
                </div>
            );
        if (subscription && subscription.active)
            return (
                <>
                    <Hero/>
                    {namespaces.length !== 0 ?
                        <NavigationRow>
                            <NavigationButton link={'/app/' + namespaces[namespaces.length - 1].id} text={'Go to recent namespace'}/>
                        </NavigationRow>
                        : ''}
                    <NamespaceList title={'Namespaces'}>
                        {namespaces.length !== 0 ? namespaces.map((namespace) => {
                            return <Namespace key={namespace.id} namespace={namespace}/>
                        }) : ''}
                    </NamespaceList>
                    <NavigationRow>
                        <NavigationButton text={showForm ? 'Close' : 'Create new'} handler={toggleForm}/>
                    </NavigationRow>
                    {showForm ? <NamespaceForm edit={false} addNamespace={addNamespace}/> : ''}
                    <TipsTricks/>
                    <AccountDetails/>
                </>
            );
        else return (
            <div className={classes.statusDisplay}>
                <AccountDetails/>
            </div>
        );
    }
;

export default Account;