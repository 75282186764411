import React from 'react';
import classes from './Story.module.css';

const Story = ({quote, author}) => {
    return (
        <div className={classes.container}>
            <p className={classes.quoteEm}>"</p>
            <p className={classes.quote}>{quote}</p>
            {/*<p className={classes.author}>{author}</p>*/}
        </div>
    );
};

export default Story;