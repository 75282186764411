import React from 'react';
import classes from './NativeHeader.module.css';
import {useNavigate} from "react-router-dom";

const NativeHeader = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    };

    const handleSupport = () => {
        navigate('/support');
    };


    return (
        <div className={classes.container}>
            <div className={classes.item} onClick={handleBack}>&lsaquo; Back</div>
            <div></div>
            <div className={classes.item} onClick={handleSupport}>&#9432; Support</div>
        </div>
    );
};

export default NativeHeader;