import React, {useState} from 'react';
import Footer from "./Footer";
import Header from "./Header";
import classes from './MasterLayout.module.css';
import {UserContext} from "../context/AuthContext";
import {Capacitor} from "@capacitor/core";
import NativeHeader from "./NativeHeader";

const MasterLayout = ({children}) => {
    const [userContext, setUserContext] = useState({});

    return (
        <UserContext.Provider value={{userContext: userContext, setUserContext : setUserContext}}>
            {Capacitor.getPlatform() === 'ios' ? <NativeHeader/> : <Header/>}
            <div className={classes.container}>
                {children}
            </div>
            {Capacitor.getPlatform() === 'ios' ? '' : <Footer/>}
        </UserContext.Provider>
    );
};

export default MasterLayout;