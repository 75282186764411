import classes from './NavigationRow.module.css';
import React from 'react';

const NavigationRow = ({children}) => {
    return (
        <div className={classes.container}>
            {children}
        </div>
    );
};

export default NavigationRow;