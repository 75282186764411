import React, {useContext, useEffect} from 'react';
import LoginForm from "../LoginForm";
import {checkAuth} from "../../api/api";
import {UserContext} from "../../context/AuthContext";
import {useNavigate, useSearchParams} from "react-router-dom";

const LoginPage = () => {
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // needs redirecting to home if authed
    useEffect(() => {

        async function setAuthStates() {
            await checkAuth(userContext, setUserContext, navigate, false, false);
            if (userContext.user)
                navigate('/app');
        }

        if (!searchParams.get('continue'))
            setAuthStates();
    }, [navigate, searchParams, userContext, setUserContext]);

    return (
        <>
            <LoginForm/>
        </>
    );
};

export default LoginPage;