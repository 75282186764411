import React from 'react';
import classes from './TaskHeader.module.css';

const TaskHeader = ({content, subtext}) => {
    return (
        <div className={classes.container}>
            <h3 className={classes.task}>{content}</h3>
            {subtext ?
                <h5 className={classes.subtext}>{subtext}</h5>
                : ''}
        </div>
    );
};

export default TaskHeader;