import React from 'react';
import classes from './HowItWorks.module.css';
import graphic from '../media/pragmate graphic.svg';

const HowItWorks = () => {
    return (
        <div className={classes.container}>
            <div className={classes.imgSide}>
                <img src={graphic} alt={'Illustration of finding a note'}/>
            </div>
            <div className={classes.textSide}>
                <h2>How it works</h2>
                <ol className={classes.list}>
                    <li>Make a note of your thoughts when you're most motivated.</li>
                    <li>Come to Pragmate when you're struggling.</li>
                    <li>Get back what you need to drive you, <span className={classes.altColor}>when you need it most.</span></li>
                </ol>
            </div>
        </div>
    );
};

export default HowItWorks;