import React, {useContext, useEffect} from 'react';
import SignupForm from "../SignupForm";
import {UserContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {checkAuth} from "../../api/api";

const SignUpPage = () => {
    const userContext = useContext(UserContext).userContext;
    const setUserContext = useContext(UserContext).setUserContext;
    const navigate = useNavigate();

    // needs redirecting to home if authed
    useEffect(() => {
        async function setAuthStates() {
            await checkAuth(userContext, setUserContext, navigate, false, false);
            if (userContext.user)
                navigate('/app');
        }

        setAuthStates();
    }, [navigate, userContext, setUserContext]);

    return (
        <>
            <SignupForm/>
        </>
    );
};

export default SignUpPage;